import React from 'react';

function AboutUs() {
  return (
    <div className="bg-gray-100 text-gray-800">
      {/* Header Section */}
      <header className="bg-blue-900 text-white py-6">
        <div className="container mx-auto px-4">
          <h1 className="text-3xl font-bold text-center">About Us</h1>
        </div>
      </header>

      {/* Content Section */}
      <main className="py-10">
        <div className="container mx-auto px-4 md:px-8">
          {/* Who We Are Section */}
          <section className="bg-white shadow-md rounded-lg p-6 md:p-10 mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Who We Are</h2>
            <p className="text-gray-600 leading-relaxed">
              Business Process and Performance Review is a leading platform committed to delivering expert insights and strategic guidance across a diverse range of industries. Just as the name implies, we look at all business-related issues by examining the business process and performance management analytical viewpoints.
            </p>
          </section>

          {/* What We Do Section */}
          <section className="bg-white shadow-md rounded-lg p-6 md:p-10 mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">What We Do</h2>
            <p className="text-gray-600 leading-relaxed">
              Through our meticulously curated magazine editions, we cover critical topics such as Engineering, Finance, Construction, Data Analytics, Customer Service and Experience Management, Tourism, Education, SME Development, and more.
            </p>
            <p className="text-gray-600 leading-relaxed mt-4">
              Each article is crafted to address contemporary challenges, drive innovation, and empower professionals to optimize processes, elevate performance, and achieve sustainable success.
            </p>
          </section>

          {/* Our Mission Section */}
          <section className="bg-white shadow-md rounded-lg p-6 md:p-10">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Our Mission</h2>
            <p className="text-gray-600 leading-relaxed">
              Our mission is to equip businesses with the knowledge and tools needed to streamline processes, enhance efficiency, and foster long-term growth. By addressing sector-specific challenges and opportunities, we are dedicated to inspiring innovation and helping organizations thrive in an ever-changing global landscape.
            </p>
          </section>
        </div>
      </main>
    </div>
  );
}

export default AboutUs;
