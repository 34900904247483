import React from 'react'

function Footer() {
  return (
    <div className='text-center mt-16 p-6'>
        Copyright ©2022 BPPR Magazine - All rights reserved.
    </div>
  )
}

export default Footer