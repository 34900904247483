import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Articles = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const [hasNextPage, setHasNextPage] = useState(false); // Check for next page
  const [hasPreviousPage, setHasPreviousPage] = useState(false); // Check for previous page

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        setLoading(true);
        // const response = await axios.get(`http://127.0.0.1:8000/api/articles/?page=${currentPage}`);
        const response = await axios.get(`https://spns-magazine-backend.onrender.com/api/articles/?page=${currentPage}`);
        setArticles(response.data.results); // Assuming `results` contains paginated data
        setHasNextPage(!!response.data.next); // `next` contains the next page URL or is null
        setHasPreviousPage(!!response.data.previous); // `previous` contains the previous page URL or is null
      } catch (err) {
        setError("Failed to fetch articles. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, [currentPage]); // Refetch articles when currentPage changes

  const handleNext = () => {
    if (hasNextPage) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevious = () => {
    if (hasPreviousPage) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  // if (loading) return <p>Loading...</p>;
  if (loading) {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mt-28">
        {Array(6)
          .fill(0)
          .map((_, index) => (
            <div key={index} className="bg-white rounded-lg shadow-md p-4">
              <Skeleton height={160} />
              <div className="p-4">
                <Skeleton height={20} width="70%" />
                <Skeleton height={15} width="50%" className="mt-2" />
                <Skeleton height={30} width="100%" className="mt-4" />
              </div>
            </div>
          ))}
      </div>
    );
  }
  if (error) return <p>{error}</p>;

  return (
    <div className="container mx-auto p-4 mt-24">
      {articles.length === 0 ? (
        <p className="text-center text-gray-500">No articles available.</p>
      ) : (
        <>
          <h1 className="text-2xl font-bold mb-4">All Articles</h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {articles.map((article) => (
              <div
                key={article.id}
                className="bg-white rounded-lg shadow-md hover:shadow-xl transition-shadow p-4"
              >
                {/* Image section */}
                <div className="h-40 bg-gray-100 rounded-t-lg flex items-center justify-center">
                  {article.image ? (
                    <LazyLoadImage
                      src={article.image}
                      alt={article.title}
                      className="w-full h-full object-cover rounded-t-lg"
                    />
                  ) : (
                    <span className="text-gray-400">Image Placeholder</span>
                  )}
                </div>

                {/* Article content */}
                <div className="p-4">
                  <h2 className="text-xl font-semibold text-gray-700 mb-2">{article.title}</h2>
                  <p className="text-sm text-gray-500">
                    <span className="font-bold">Editor:</span>{article.contributor?.full_name || "No description available."}
                  </p>
                  <p className="text-sm text-gray-500 mb-4">
                    Industry: {article.industry_display  || "No industry available."}
                  </p>
                  <Link
                    to={`/articles/${article.id}`}
                    className="text-[#b3976b] underline hover:text-[#b3976b]"
                  >
                    View Article Details
                  </Link>
                </div>
              </div>
            ))}
          </div>

          {/* Pagination Controls */}
          <div className="flex justify-center mt-6">
            <button
              onClick={handlePrevious}
              disabled={!hasPreviousPage}
              className={`px-4 py-2 mr-2 border rounded ${
                hasPreviousPage ? "bg-[#b3976b] text-white" : "bg-gray-300 text-gray-500 cursor-not-allowed"
              }`}
            >
              Previous
            </button>
            <button
              onClick={handleNext}
              disabled={!hasNextPage}
              className={`px-4 py-2 ml-2 border rounded ${
                hasNextPage ? "bg-[#b3976b] text-white" : "bg-gray-300 text-gray-500 cursor-not-allowed"
              }`}
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Articles;


// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { Link } from "react-router-dom";

// const Articles = () => {
//   const [articles, setArticles] = useState([]);
//   const [filteredArticles, setFilteredArticles] = useState([]); // For filtered results
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState("");
//   const [currentPage, setCurrentPage] = useState(1);
//   const [hasNextPage, setHasNextPage] = useState(false);
//   const [hasPreviousPage, setHasPreviousPage] = useState(false);
//   const [searchQuery, setSearchQuery] = useState(""); // Search input state

//   useEffect(() => {
//     const fetchArticles = async () => {
//       try {
//         setLoading(true);
//         // const response = await axios.get(
//         //   `https://spns-magazine-backend.onrender.com/api/articles/?page=${currentPage}`
//         // );

//         const response = await axios.get(`http://127.0.0.1:8000/api/articles/?page=${currentPage}`);
//         setArticles(response.data.results);
//         setFilteredArticles(response.data.results); // Initialize filteredArticles
//         setHasNextPage(!!response.data.next);
//         setHasPreviousPage(!!response.data.previous);
//       } catch (err) {
//         setError("Failed to fetch articles. Please try again later.");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchArticles();
//   }, [currentPage]);

//   const handleNext = () => {
//     if (hasNextPage) {
//       setCurrentPage((prevPage) => prevPage + 1);
//     }
//   };

//   const handlePrevious = () => {
//     if (hasPreviousPage) {
//       setCurrentPage((prevPage) => prevPage - 1);
//     }
//   };

//   // Handle search functionality
//   const handleSearch = (e) => {
//     const query = e.target.value.toLowerCase();
//     setSearchQuery(query);

//     const filtered = articles.filter((article) => {

//       // console.log("Query:", query);
//       // console.log("Article Publication Date:", article.publication_date);

//       // const year = article.publication_date?.slice(0, 4); // Extract 'YYYY'
//       // console.log("Extracted Year:", year);

//       // const YearMatch = year && year.includes(query);
//       // console.log("Year Match:", YearMatch);

//       const normalizedQuery = query.trim(); // Remove extra spaces
  
//       const year = article.publication_date?.slice(0, 4); // Extract year
//       const YearMatch = year && year === normalizedQuery; // Exact match

//       const titleMatch = article.title.toLowerCase().includes(query);
//       const industryMatch =
//         article.industry_display?.toLowerCase().includes(query);
//       const contributorMatch =
//         article.contributor?.full_name?.toLowerCase().includes(query);

//       // const YearMatch =
//       //   article.publication_date.toLowerCase().includes(query);

//         // Extract the year from publication_date
//       // const year = article.publication_date?.slice(0, 4); // Extract 'YYYY' from 'YYYY-MM-DD'
//       // const YearMatch = year && year.includes(query)

//       // Extract the year from publication_date
//       // const year = article.publication_date ? article.publication_date.slice(0, 4) : ""; // Fallback to empty string if null/undefined
//       // const YearMatch = year.includes(query);

//       return titleMatch || industryMatch || contributorMatch || YearMatch;
//     });

//     setFilteredArticles(filtered);
//   };

//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>{error}</p>;

//   return (
//     <div className="container mx-auto p-4 mt-16">
//       <div className="mb-6 mt-20 flex justify-center">
//         {/* Search Input */}
//         <input
//           type="text"
//           value={searchQuery}
//           onChange={handleSearch}
//           placeholder="Search by name, industry, YearMatch, or contributor..."
//           className="w-[50%] p-2 border rounded outline-[#b3976b]"
//         />
//       </div>

//       {filteredArticles.length === 0 ? (
//         <p className="text-center text-gray-500">No articles found.</p>
//       ) : (
//         <>
//           <h1 className="text-2xl font-bold mb-4">All Articles</h1>
//           <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
//             {filteredArticles.map((article) => (
//               <div
//                 key={article.id}
//                 className="bg-white rounded-lg shadow-md hover:shadow-xl transition-shadow p-4"
//               >
//                 <div className="h-40 bg-gray-100 rounded-t-lg flex items-center justify-center">
//                   {article.image ? (
//                     <img
//                       src={article.image}
//                       alt={article.title}
//                       className="w-full h-full object-cover rounded-t-lg"
//                     />
//                   ) : (
//                     <span className="text-gray-400">Image Placeholder</span>
//                   )}
//                 </div>

//                 <div className="p-4">
//                   <h2 className="text-xl font-semibold text-gray-700 mb-2">
//                     {article.title}
//                   </h2>
//                   <p className="text-sm text-gray-500">
//                     <span className="font-bold">Editor:</span>
//                     {article.contributor?.full_name ||
//                       "No contributor available."}
//                   </p>
//                   <p className="text-sm text-gray-500 mb-4">
//                     Industry: {article.industry_display || "No industry available."}
//                   </p>
//                   <Link
//                     to={`/articles/${article.id}`}
//                     className="text-[#b3976b] underline hover:text-[#b3976b]"
//                   >
//                     View Article Details
//                   </Link>
//                 </div>
//               </div>
//             ))}
//           </div>

//           <div className="flex justify-center mt-6">
//             <button
//               onClick={handlePrevious}
//               disabled={!hasPreviousPage}
//               className={`px-4 py-2 mr-2 border rounded ${
//                 hasPreviousPage
//                   ? "bg-[#b3976b] text-white"
//                   : "bg-gray-300 text-gray-500 cursor-not-allowed"
//               }`}
//             >
//               Previous
//             </button>
//             <button
//               onClick={handleNext}
//               disabled={!hasNextPage}
//               className={`px-4 py-2 ml-2 border rounded ${
//                 hasNextPage
//                   ? "bg-[#b3976b] text-white"
//                   : "bg-gray-300 text-gray-500 cursor-not-allowed"
//               }`}
//             >
//               Next
//             </button>
//           </div>
//         </>
//       )}
//     </div>
//   );
// };

// export default Articles;
